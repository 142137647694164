import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import {
  Form,
  Table,
  Dropdown,
  message,
  DatePicker,
  Modal,
  Tabs,
  AutoComplete,
  InputNumber
} from 'antd';

import history from "../../../history";
import {
  allowClose,
  allowCommit,
  searchBusinessUsers,
  close,
  setInvestmentInfo,
  reopen,
  getBusiness,
  setBusinessExpired,
  setPegateShareholding,
  removeBusinessInvestor,
  searchBusinessUserBids,
  searchBusinessInvolveRequests,
  addInvolveRequest,
} from "../../../redux/business/thunks/businessThunk";
import { searchNotInvolvedUsers } from '../../../redux/users/thunks/searchUsersThunk';
import { getNDA } from "../../../redux/business/thunks/ndaThunk";
import { removeCurrentBusiness } from "../../../redux/business/actions/businessActions";
import { getMediaFileFromServer } from "../../../utils/getMediaFileFromServer";
import { formatDate } from "../../../utils/formatDate";
import { formatNumber } from "../../../utils/formatNumber";
import { handleLoading } from "../../../utils/handleLoading";
import { goBack } from "../../../utils/goBack";

import { winningBidsColumns } from "./WinningBidsColumns";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import PopUp from "../../../components/PopUp/PopUp";
import NumericInput from "../../../components/NumericInput/NumericInput";
import loadingGif from '../../../assets/img/loading.gif';
import './style.scss';
import TabPaneTitle from '../../Opportunities/TabPaneTitle';

const { TabPane } = Tabs;
const {Option} = AutoComplete;

class UserStatuses extends Component {
  constructor(props) {
    super(props);

    this.usersColumns = [
      {
        title: 'Name Surname',
        dataIndex: 'nameSurname',
        key: 'nameSurname',
        render: (payload) =>
          <div className="flex-container align-middle">
            {
              !payload[1].isTotal ?
                <span className="radius-circle clip flex-container align-middle align-center user_ava">
                  {
                    payload[0] ?
                      <img src={payload[0]} alt="" />
                      :
                      <div className="text text-15 font-heavy font-spacing-02 color-blue">
                        {payload[1].text.charAt(0)}
                      </div>
                  }
                </span>
                : null
            }

            <span className="text text-16 font-semibold text-height-20 color-grey">
              <a className="color-grey" target="_blank" rel="noopener noreferrer" href={payload[1].link}>
                <span>{payload[1].text}</span>
                <br />
                <span className="text text-12 font-semibold color-grey-800 hover-inherit" >
                  {payload[1].email}
                </span>
              </a>
            </span>
          </div>,
      },
      {
        title: 'Not interested',
        dataIndex: 'not_interested',
        key: 'not_interested ',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Not interested:
            </span>
            {text}
          </div>,
      },
      {
        title: 'Interested',
        dataIndex: 'interested',
        key: 'interested',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Interested:
            </span>
            {text}
          </div>,
      },
      {
        title: 'NDA signed',
        dataIndex: 'ndaSigned',
        key: 'ndaSigned',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              NDA signed:
            </span>
            {text}
          </div>,
      },
      {
        title: 'Initial Amount',
        dataIndex: 'initialAmount',
        key: 'initialAmount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Initial Amount:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'AA and DD',
        dataIndex: 'aaAndDD',
        key: 'aaAndDD',
        render: ({ aa, dd }) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              AA and DD
            </span>
            <p className="m_0 flex-container flex-dir-column" >
              <span style={{ marginBottom: 5 }} >{formatNumber(aa)}</span>
              <span>{formatNumber(dd)}</span>
            </p>
          </div>,
      },
      {
        title: 'Committed',
        dataIndex: 'readyToCommit',
        key: 'readyToCommit',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Committed:
            </span>
            {text}
          </div>,
      },
      {
        title: 'Due Diligence',
        dataIndex: 'real_diligence',
        key: 'real_diligence',
        render: (number) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Due Diligence:
            </span>
            {formatNumber(number)}
          </div>,
      },
      {
        title: 'Primary Received',
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Primary Received:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Internal Received',
        dataIndex: 'internalCost',
        key: 'internalCost',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Internal Received:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Internal number of shares',
        dataIndex: 'internalPrize',
        key: 'internalPrize',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Internal Number of shares:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'External Received',
        dataIndex: 'externalCost',
        key: 'externalCost',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              External Received:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'External Number of shares',
        dataIndex: 'externalPrize',
        key: 'externalPrize',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              External Number of shares:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Secondary Total Received',
        dataIndex: 'secondaryTotalAmount',
        key: 'secondaryTotalAmount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Secondary Total Received
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Secondary Total Number of Shares',
        dataIndex: 'secondaryTotalShares',
        key: 'secondaryTotalShares',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
            Secondary Total Number of Shares
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Total Received',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Total Received:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Closed',
        dataIndex: 'closed',
        key: 'closed',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Closed:
            </span>
            {text}
          </div>,
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (payload) => {
          if (payload) {
            return (
              <div>
                <Dropdown
                  overlay={
                    <div className="bg-white radius-4 shadow-layout drop_menu">
                      <div
                        className="drop_menu_item cursor"
                        onClick={() => this.toggleOpportunityModal(true, payload.userID)}
                      >
                        <i className="icon icon-pen" />
                        <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                          Set investment info
                        </span>
                      </div>
                      <div
                        className="drop_menu_item cursor"
                        onClick={() => this.toggleBidsModal(true, payload.userID)}
                      >
                        {/* <i className="icon icon-pen" /> */}
                        <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                          View Investor winning bids
                        </span>
                      </div>
                      {
                        payload.ndaSignature ?
                          <div
                            className="drop_menu_item cursor"
                            onClick={() => this.toggleModal(true, payload.ndaSignature, payload.ndaSigned)}
                          >
                            <i className="icon icon-pen" />
                            <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                              See NDA
                            </span>
                          </div>
                          : null
                      }
                      {
                        payload.initialAmount ?
                          <div className="drop_menu_item cursor" onClick={() => this.toggleAllowCommitModal(true, payload.userID)}>
                            <i className="icon icon-checked" />
                            <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                              Set AA and DD
                            </span>
                          </div>
                          : null
                      }

                      {
                        payload.closed ?
                          <div className="drop_menu_item cursor" onClick={() => this.handleReopen(payload.userID)}>
                            <i className="icon icon-checked" />
                            <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                              Reopen the deal
                            </span>
                          </div>
                          :
                          payload.readyToCommit ?
                            <div className="drop_menu_item cursor" onClick={() => this.handleClose(payload.userID)}>
                              <i className="icon icon-cancel" />
                              <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                                Close the deal
                              </span>
                            </div>
                            : null
                      }
                      <div
                        className="drop_menu_item cursor"
                        onClick={() => this.handleRemoveOnClick(payload.businessID, payload.userID)}
                      >
                        <i className="icon icon-bin" />
                        <span className="text text-14 font-medium font-uppercase font-spacing-04 color-grey">
                          Remove User from Opportunity
                        </span>
                      </div>
                    </div>
                  }
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <div className="icon_btn">
                    <Button
                      title={<Icon type="tools" style={{ fontSize: "19px" }} />}
                      size="lg"
                      theme="lightblue"
                      className="radius-10"
                    />
                  </div>
                </Dropdown>
              </div>
            );
          }
          return null;
        }
      },
    ];

    this.involveRequestsColumns = [
      {
        title: 'Name Surname',
        dataIndex: 'nameSurname',
        key: 'nameSurname',
        render: (payload) =>
          <div className="flex-container align-middle">
            {
              !payload[1].isTotal ?
                <span className="radius-circle clip flex-container align-middle align-center user_ava">
                  {
                    payload[0] ?
                      <img src={payload[0]} alt="" />
                      :
                      <div className="text text-15 font-heavy font-spacing-02 color-blue">
                        {payload[1].text.charAt(0)}
                      </div>
                  }
                </span>
                : null
            }

            <span className="text text-16 font-semibold text-height-20 color-grey">
              <a className="color-grey" target="_blank" rel="noopener noreferrer" href={payload[1].link}>
                <span>{payload[1].text}</span>
                <br />
                <span className="text text-12 font-semibold color-grey-800 hover-inherit" >
                  {payload[1].email}
                </span>
              </a>
            </span>
          </div>,
      },
      {
        title: 'Due Diligence',
        dataIndex: 'real_diligence',
        key: 'real_diligence',
        render: (number) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Due Diligence:
            </span>
            {formatNumber(number)}
          </div>,
      },
      {
        title: 'Fmv',
        dataIndex: 'fmv',
        key: 'fmv',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Fmv:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Irr',
        dataIndex: 'irr',
        key: 'irr',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Irr:
            </span>
            {formatNumber(text)}
          </div>,
      },
      // {
      //   title: 'Shareholding',
      //   dataIndex: 'shareholding',
      //   key: 'shareholding',
      //   render: (text) =>
      //     <div>
      //       <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
      //         Shareholding:
      //       </span>
      //       {formatNumber(text)}
      //     </div>,
      // },
      {
        title: 'Returned Amount',
        dataIndex: 'returned_amount',
        key: 'returned_amount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Returned Amount:
            </span>
            {formatNumber(text)}
          </div>,
      },
      {
        title: 'Real Date',
        dataIndex: 'final_date',
        key: 'final_date',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Real Date:
            </span>
            {text}
          </div>,
      },
      {
        title: 'Primary Received',
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        render: (text) =>
          <div>
            <span className="text text-14 font-semibold text-height-20 color-grey hide-lg show-md">
              Primary Received:
            </span>
            {formatNumber(text)}
          </div>,
      },
    ];

    this.state = {
      popupVisible: false,
      opportunityPopupVisible: false,
      involvePopupVisible: false,
      bidsPopupVisible: false,
      currentUserID: null,
      foundUserData: null,
      ndaSignature: null,
      ndaSigned: null,
      isSummaryPopupVisible: false,
      selectedRowKeysInbox: [],
      selectedRowKeysSent: [],
      allowToCommitPopupVisible: false,
      investorsFilter: null,
    }
  }

  handleAllowCommit = (userID) => {
    const { allowCommit, match } = this.props;
    const { businessID } = match.params;
    allowCommit(userID, businessID);
  };

  toggleAllowCommitModal = (status, userID) => {
    let foundUserData = null;
    if (status) {
      const { businessUsers } = this.props;
      foundUserData = businessUsers.find(item => parseInt(item.id) === parseInt(userID));
    }
    this.setState({
      allowToCommitPopupVisible: status,
      currentUserID: status ? userID : null,
      foundUserData,
    });
  };

  handleAllowClose = (userID) => {
    const { allowClose, match } = this.props;
    const { businessID } = match.params;
    allowClose(userID, businessID);
  };

  handleClose = (userID) => {
    const { close, match } = this.props;
    const { businessID } = match.params;
    close(userID, businessID);
  };

  handleReopen = (userID) => {
    const { reopen, match } = this.props;
    const { businessID } = match.params;
    reopen(userID, businessID);
  };

  toggleOpportunityModal = (status, currentUserID = null) => {
    let foundUserData = null;
    if (status) {
      const { businessUsers } = this.props;
      foundUserData = businessUsers.find(item => parseInt(item.id) === parseInt(currentUserID));
    }
    this.setState({
      opportunityPopupVisible: status,
      currentUserID,
      foundUserData,
    }, () => {
      if (!status) {
        this.props.form.resetFields();
      }
    });
  };

  toggleBidsModal = (status, userID) => {
    if (status) {
      const { match, searchBusinessUserBids } = this.props;
      const { businessID } = match.params;

      searchBusinessUserBids(businessID, userID);
    }

    this.setState({
      bidsPopupVisible: status,
    });
  };

  toggleInvolveModal = (status) => {
    const { notInvolvedUsers, searchNotInvolvedUsers, match } = this.props;
    const { businessID } = match.params;

    if(!notInvolvedUsers) {
      searchNotInvolvedUsers({ businessID, params: {}, hardCodeOffset: 0 });
    }

    this.setState({
      involvePopupVisible: status,
    });
  };


  handleRemoveOnClick = (business, investor) => {
    Modal.confirm({
      title: "Are you sure you want to remove user?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        const { removeBusinessInvestor } = this.props;
        removeBusinessInvestor({ business, investor });
      }
    });

  }

  toggleModal = (status, ndaSignature, ndaSigned) => {
    this.setState({
      popupVisible: status,
      ndaSignature: ndaSignature ? ndaSignature : null,
      ndaSigned: ndaSigned ? ndaSigned : null,
    }, () => {
      if (status) {
        window.scrollTo(0, 0);
      }
    });
  };

  handleOnRowClick = userID => {
    history.push(`/admin-users-inner/${userID}`);
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { form, match } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        delete values.allocation_amount;
        delete values.diligence_amount;

        const { setInvestmentInfo } = this.props;
        const { currentUserID } = this.state;
        values.business = match.params.businessID;
        values.investor = currentUserID;
        values.final_date = values.final_date.format('YYYY-MM-DD');
        setInvestmentInfo(values);
      }
    });
  };
  
  onInvolveSubmit = (event) => {
    event.preventDefault();
    const { form, match } = this.props;

    form.validateFields(
      ['final_amount', 'final_date', 'returned_amount', 'fmv', 'real_diligence', 'irr', 'investor'] ,(err, values) => {
      if (!err) {
        const { addInvolveRequest } = this.props;
        values.business = +match.params.businessID;
        values.investor = +values.investor;
        values.final_date = values.final_date.format('YYYY-MM-DD');
        addInvolveRequest(values);
      }
    });
  };

  onAllowToCommitSubmit = (event) => {
    event.preventDefault();
    const { form } = this.props;

    form.validateFields(['allocation_amount', 'diligence_amount'])
      .then((values) => {
        const { match, allowCommit } = this.props;
        const { currentUserID } = this.state;
        const { businessID } = match.params;

        allowCommit({
          user: currentUserID,
          business: businessID,
          allocation_amount: values.allocation_amount,
          diligence_amount: values.diligence_amount,
        });
      })
      .catch(() => { });
  };

  getBusinessUsersData = () => {
    const { businessUsers, match } = this.props;
    const { businessID } = match.params;
    let usersData = [];
    let initialAmountSum = 0;
    let aaSum = 0;
    let ddSum = 0;
    let finalAmountSum = 0;
    let realDiligenceSum = 0;
    let internalCostSum = 0;
    let internalPrizeSum = 0;
    let externalCostSum = 0;
    let externalPrizeSum = 0;
    let secondaryTotalSum = 0;
    let secondaryTotalSharesSum = 0;
    let totalSum = 0;

    if (businessUsers) {
      businessUsers.map(element => {
        const totalAmount = ((element.final_amount + element.internal_prize + element.external_prize) - element.external_sales) - element.internal_sales;
        const secondaryTotal = element.internal_cost + element.external_cost;
        const secondaryTotalShares = element.internal_prize + element.external_prize;

        usersData.push({
          key: element.id,
          nameSurname: [
            element.avatar ? getMediaFileFromServer(element.avatar) : null,
            {
              link: `/admin-users-inner/${element.id}`,
              text: `${element.first_name} ${element.last_name}`,
              email: element.email,
            }
          ],
          not_interested: element.not_interested ? formatDate(element.not_interested) : '-',
          interested: element.interested ? formatDate(element.interested) : '-',
          ndaSigned: element.nda_signed ? formatDate(element.nda_signed) : '-',
          readyToStart: element.ready_to_start ? formatDate(element.ready_to_start) : '-',
          initialAmount: element.initial_amount ? element.initial_amount : '-',
          aaAndDD: { aa: element.allocation_amount, dd: element.diligence_amount },
          readyToCommit: element.ready_to_commit ? formatDate(element.ready_to_commit) : '-',
          readyToClose: element.ready_to_close ? formatDate(element.ready_to_close) : '-',
          finalAmount: element.final_amount ? formatNumber(element.final_amount) : '-',
          internalCost: element.internal_cost ? formatNumber(element.internal_cost) : '-',
          internalPrize: element.internal_prize ? formatNumber(element.internal_prize) : '-',
          externalCost: element.external_cost ? formatNumber(element.external_cost) : '-',
          externalPrize: element.external_prize ? formatNumber(element.external_prize) : '-',
          secondaryTotalAmount: secondaryTotal ? formatNumber(secondaryTotal) : "-",
          secondaryTotalShares: secondaryTotalShares ? formatNumber(secondaryTotalShares) : "-",
          totalAmount: totalAmount ? formatNumber(totalAmount) : '-',
          real_diligence: element.real_diligence ? formatNumber(element.real_diligence) : '-',
          closed: element.closed ? formatDate(element.closed) : '-',
          actions: {
            ndaSignature: element.nda_signature ? element.nda_signature : null,
            ndaSigned: element.nda_signed ? formatDate(element.nda_signed) : null,
            userID: element.id,
            businessID,
            initialAmount: element.initial_amount ? element.initial_amount : null,
            readyToClose: element.ready_to_close ? element.ready_to_close : null,
            readyToCommit: element.ready_to_commit ? element.ready_to_commit : null,
            closed: element.closed ? element.closed : null,
          },
        });
        initialAmountSum += element.initial_amount;
        aaSum += element.allocation_amount;
        ddSum += element.diligence_amount;
        finalAmountSum += element.final_amount;
        realDiligenceSum += element.real_diligence;
        internalCostSum += element.internal_cost;
        internalPrizeSum += element.internal_prize;
        externalCostSum += element.external_cost;
        externalPrizeSum += element.external_cost;
        secondaryTotalSum += secondaryTotal;
        secondaryTotalSharesSum += secondaryTotalShares;
        totalSum += totalAmount;
        return "";
      });
    }

    usersData.push({
      key: usersData.length,
      nameSurname: [
        null,
        {
          link: null,
          text: 'Total',
          email: null,
          isTotal: true,
        },
      ],
      not_interested: '-',
      interested: '-',
      ndaSigned: '-',
      readyToStart: '-',
      initialAmount: formatNumber(initialAmountSum),
      aaAndDD: { aa: formatNumber(aaSum), dd: formatNumber(ddSum) },
      readyToCommit: '-',
      readyToClose: '-',
      finalAmount: formatNumber(finalAmountSum),
      internalCost: formatNumber(internalCostSum),
      internalPrize: formatNumber(internalPrizeSum),
      externalCost: formatNumber(externalCostSum),
      externalPrize: formatNumber(externalPrizeSum),
      secondaryTotalAmount: formatNumber(secondaryTotalSum),
      secondaryTotalShares: formatNumber(secondaryTotalSharesSum),
      totalAmount: formatNumber(totalSum),
      real_diligence: formatNumber(realDiligenceSum),
      closed: '-',
      actions: null,
    });

    return usersData;
  };

  getBusinessInvolveRequestsData = () => {
    const { businessInvolveRequests } = this.props;
    let requestsData = [];

    if (businessInvolveRequests) {
      businessInvolveRequests.map(element => {

        requestsData.push({
          key: element.id,
          nameSurname: [
            element.avatar ? getMediaFileFromServer(element.avatar) : null,
            {
              link: `/admin-users-inner/${element.id}`,
              text: `${element.first_name} ${element.last_name}`,
              email: element.email,
            }
          ],
          finalAmount: element.final_amount ? formatNumber(element.final_amount) : '-',
          real_diligence: element.real_diligence ? formatNumber(element.real_diligence) : '-',
          final_date: element.final_date ? element.final_date : '-',
          fmv: element.fmv ? formatNumber(element.fmv) : '-',
          irr: element.irr ? formatNumber(element.irr) : '-',
          returned_amount: element.returned_amount ? formatNumber(element.returned_amount) : '-',
          // shareholding: element.shareholding ? formatNumber(element.shareholding) : '-',
        });
        return "";
      });
    }

    return requestsData;
  };

  bidsList = () => {
    const { currentUserWinningBids } = this.props;

    return currentUserWinningBids?.map((bid) => ({
      id: bid.id,
      bid: formatNumber(bid.amount),
      scope: bid.scope,
      prize: bid.prize,
      won: moment.utc(bid.won).local().format('YYYY-MM-DD hh:mm:ss'),
      created: moment.utc(bid.created).local().format('YYYY-MM-DD hh:mm:ss'),
    }));
  }

  getNDAOnClick = () => {
    const { getNDA } = this.props;
    getNDA();
  };

  toggleSummaryPopup = () => {
    this.setState((prevState) => ({
      isSummaryPopupVisible: !prevState.isSummaryPopupVisible,
    }));
  };

  handleSetExpiredOnClick = () => {
    const { match, setBusinessExpired } = this.props;
    const { businessID } = match.params;

    setBusinessExpired({
      id: businessID,
      expired: parseInt(this.getBusinessExpiredStatus()) === 1 ? 0 : 1,
    });
  };

  getBusinessExpiredStatus = () => {
    const { currentOpenedBusiness } = this.props;
    if (currentOpenedBusiness) {
      return currentOpenedBusiness.expired;
    }

    return null;
  };

  disabledDates = (current) => current && current >= moment().add(0, "days").endOf("day");

  isSignatureAnImage = (signature) => {
    if (signature) {
      return signature.includes('.png') || signature.includes('.jpg') || signature.includes('.jpeg');
    }
    return false;
  };
  setPegateShareholdingValue = (event) => {
    event.preventDefault();
    const { setPegateShareholding, match, form } = this.props
    const { businessID } = match.params;

    form.validateFields(['pegate_shareholder'])
      .then((values) => {
        if (Number(values.pegate_shareholder) > 100 || Number(values.pegate_shareholder) < 0) {
          form.setFields({
            pegate_shareholder: {
              value: form.getFieldValue("pegate_shareholder"),
              errors: [new Error("Please input number from 0 to 100")]
            }
          });
        }
        else {
          setPegateShareholding({ id: businessID, shareholding: values.pegate_shareholder })
        }
      })
      .catch(() => { });
  }

  infiniteScroll = (e) => {
    e.persist();

    const { notInvolvedUsers } = this.props;
    const dropdown = e.target;
    const option = dropdown?.querySelector("li");
    const optionRect = option.getBoundingClientRect();
    const optionHeight = optionRect.height;
    let totalHeight = Math.floor(notInvolvedUsers.length * optionHeight);
    const dropdownBottom = dropdown.scrollTop + dropdown.offsetHeight - 8;

    if (dropdownBottom >= totalHeight) {
      const { notInvolvedUsersCount, investorsCurrentPage, match } = this.props;
      const { businessID } = match.params;
      const { investorsFilter } = this.state;
      const roundedCount = Math.ceil(notInvolvedUsersCount / 100) * 100;
      const checkingNumber = investorsCurrentPage * 100;

      const params = investorsFilter || {};

      if (roundedCount >= checkingNumber) { // for avoiding of calling endless searchNotInvolvedUsers() thunk
        const { searchNotInvolvedUsers } = this.props;
        searchNotInvolvedUsers({ businessID, params });
      }
    }
  };

  handleInvestorsOnChange = (inputValue) => {
    const { searchNotInvolvedUsers, match } = this.props;
    const { businessID } = match.params;
    
    const inputValueArray = inputValue.split(/(\s+)/);
    let params = {
      first_name: inputValueArray[0] ? inputValueArray[0] : "",
      last_name: inputValueArray[2] ? inputValueArray[2] : ""
    };

    if(!inputValue) {
      this.setState({
        investorsFilter: null
      });

      params = {};
    } else {
      this.setState({
        investorsFilter: params
      });
    }

    searchNotInvolvedUsers({businessID, hardCodeOffset: 0, params});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loading, errorMessage } = this.props;
    const loadingType = loading.type;
    const loadingState = loading.state;
    const errorText = errorMessage.message;
    const errorType = errorMessage.type;

    if (!errorText && !loadingState && prevProps.loading.state) {
      if (loadingType === 'AllowCommit' || loadingType === "SetPegateShareholding" || loadingType === 'AllowClose' || loadingType === 'Close' || loadingType === 'Reopen') {
        message.success('Action was changed successfully');
      }
      if (loadingType === 'SetInvestmentInfo') {
        message.success('Opportunity info was set successfully');
        this.toggleOpportunityModal(false);
      }
      if (loadingType === 'AddInvolveRequest') {
        const { match, searchBusinessInvolveRequests } = this.props;
        const { businessID } = match.params;

        message.success('Involve request sended to investor');
        this.toggleInvolveModal(false);

        searchBusinessInvolveRequests(businessID);
      }
      if (loadingType === "RemoveBusinessInvestor") {
        message.success('User was successfully removed');
        const { match, searchBusinessUsers } = this.props;
        const { businessID } = match.params;
        searchBusinessUsers(businessID);
      }
      if (loadingType === 'AllowCommit') {
        this.toggleAllowCommitModal(false, null);
      }
    } else if (errorText && !loadingState && errorType === "SetPegateShareholding" && prevProps.loading.state) {
      message.error(errorText);
    }
  }

  componentWillUnmount() {
    const { removeCurrentBusiness } = this.props;
    removeCurrentBusiness();
  }

  componentDidMount() {
    const { match, searchBusinessUsers, getBusiness, searchBusinessInvolveRequests } = this.props;
    const { businessID } = match.params;
    searchBusinessUsers(businessID);
    searchBusinessInvolveRequests(businessID);
    getBusiness(businessID);
  }

  render() {
    const { form, loading, currentOpenedBusiness, currentUserWinningBids, notInvolvedUsers } = this.props;
    const { getFieldDecorator } = form;
    const usersData = this.getBusinessUsersData();
    const involveRequestsData = this.getBusinessInvolveRequestsData();
    const {
      popupVisible,
      ndaSignature,
      ndaSigned,
      opportunityPopupVisible,
      foundUserData,
      allowToCommitPopupVisible,
      involvePopupVisible,
      bidsPopupVisible,
    } = this.state;
    const {
      final_amount,
      returned_amount,
      shareholding,
      fmv,
      irr,
      allocation_amount,
      diligence_amount,
      real_diligence,
      final_date,
    } = foundUserData ? foundUserData : "";

    return (
      <div className="relative fluid-y screen_wrapper">
        <div className="row">
          <div className="column small-12">
            <div className="page_top page_top-tall">
              <div className="flex-container align-middle">
                <Button
                  title={<Icon type="key-left" size={16} />}
                  size="md"
                  theme="lightblue"
                  className="radius-10 back_btn"
                  onClick={goBack}
                />
                <div className="page_title visible-first custom-headline text text-28 text-height-48 font-heavy font-uppercase color-grey">
                  <h1>User status</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column small-12">
            <div className="flex-container align-right" >
              <Button
                type="button"
                title={"Add user to Bussiness"}
                size="lg"
                theme="lightblue"
                mode="bordered"
                className="radius-8 mb_25"
                style={{ fontSize: 18, marginLeft: 15 }}
                onClick={() => this.toggleInvolveModal(true)}
              />
              {
                this.getBusinessExpiredStatus() != null ?
                  <Button
                    type="button"
                    title={`${parseInt(this.getBusinessExpiredStatus()) === 1 ? 'Open' : 'Close'} the deal`}
                    size="lg"
                    theme="lightblue"
                    mode="bordered"
                    className="radius-8 mb_25"
                    style={{ fontSize: 18, marginLeft: 15 }}
                    onClick={this.handleSetExpiredOnClick}
                    loading={handleLoading(loading, 'SetBusinessExpired')}
                  />
                  : null
              }
            </div>

            <div className="table_wrapper table_wrapper_scrollable bg-white radius-10 shadow-layout expand_sm">
              <Tabs
                // onChange={this.handleTabsPaneOnChange}
                defaultActiveKey={'users'}
                className="tab_default tab_sticky"
              >
                <TabPane tab={<TabPaneTitle title='Users' />} key={'users'} >
                  <Table
                    pagination={false}
                    columns={this.usersColumns}
                    dataSource={usersData}
                    className="c-table c-table-bordered hide_paging stretch_table"
                  />
                  <Form onSubmit={this.setPegateShareholdingValue} className="pb_0 table_wrapper table_wrapper_scrollable  radius-10 expand_sm row">
                    <div className="column small-12 medium-12 p_0">
                      <div className="form-item-label text text-height-20 font-semibold color-grey">
                        % HoldCo. Owns OpCo.
                      </div>
                      <div className="flex-container align-middle">
                        <Form.Item style={{ marginRight: 10 }} className="medium-4 mb_0">
                          {getFieldDecorator("pegate_shareholder", {
                            initialValue: currentOpenedBusiness?.pegate_shareholding,
                            rules: [{ required: true, message: 'Please fill out this field' }],
                          })(
                            <NumericInput className="fluid-x" />
                          )}
                        </Form.Item>
                        <Button
                          title="Save"
                          prefix={<Icon type="checked" style={{ fontSize: 18, marginRight: 10 }} />}
                          size="lg"
                          theme="blue"
                          className="btn-shadow radius-8 font-capitalize"
                          type="submit"
                          loading={handleLoading(loading, "SetPegateShareholding")}
                        />
                      </div>

                    </div>
                  </Form>
                </TabPane>
                <TabPane tab={<TabPaneTitle title='Involve Requests' />} key={'involve'} >
                  <Table
                    pagination={false}
                    columns={this.involveRequestsColumns}
                    dataSource={involveRequestsData}
                    className="c-table c-table-bordered hide_paging stretch_table"
                  />
                </TabPane>
              </Tabs>
            </div>

          </div>
        </div>

        <PopUp
          className="relative_parent"
          visible={popupVisible}
          onCancel={() => this.toggleModal(false)}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.toggleModal(false)}>
            <Icon type="cancel" />
          </div>
          <div className="text-center">
            <div className="text text-28 font-heavy font-spacing-02 color-grey font-uppercase mb_25">
              {ndaSigned}
            </div>
            <div className="popup_icon_file">
              <Button
                title="Non disclosure agreement"
                prefix={<Icon type="file" style={{ marginRight: 10 }} />}
                size="lg"
                theme="white"
                className="btn-link"
                onClick={this.getNDAOnClick}
                style={{ fontSize: 18 }}
              />
            </div>

            <div className="radius-8 nda_img">
              {
                this.isSignatureAnImage(ndaSignature) ?
                  <img src={getMediaFileFromServer(ndaSignature)} alt="" />
                  : <div className='text text-28 font-heavy font-spacing-02' >{ndaSignature}</div>
              }
            </div>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={involvePopupVisible}
          onCancel={() => this.toggleInvolveModal(false)}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.toggleInvolveModal(false)}>
            <Icon type="cancel" />
          </div>
          <Form onSubmit={this.onInvolveSubmit} >
            <div>
              <div className="flex-container align-center text-center">
                <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
                  Add involve Request
                </div>
              </div>
              <div>
                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    INVESTOR
                  </div>
                    <Form.Item className="mb_0">
                      {getFieldDecorator('investor', {
                        initialValue: '',
                        rules: [{required: false, message: 'Please fill out this field'}],
                      })(
                        <AutoComplete
                          style={{width: '100%'}}
                          optionLabelProp={'label'}
                          onPopupScroll={this.infiniteScroll}
                          onChange={this.handleInvestorsOnChange}
                          // filterOption={(inputValue, option) => option.props.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        >
                          {
                            notInvolvedUsers && notInvolvedUsers.map((element) => (
                              <Option key={element.id} label={`${element.first_name}${element.middle_name ? " " + element.middle_name : ''} ${element.last_name}`}>
                                <span className="radius-circle clip option_ava">
                                  <img
                                    style={{width: "24px", height: "24px"}}
                                    src={element.avatar ? getMediaFileFromServer(element.avatar) : require("../../../assets/img/no_profile.png")}
                                    alt="avatar"
                                  />
                                </span>
                                <span className="font-semibold color-grey">
                                  {element.first_name} {element.middle_name ? element.middle_name : ""} {element.last_name} ({element.email})
                                </span>
                              </Option>
                            ))
                          }
                        </AutoComplete>
                      )}
                    </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    TOTAL RECEIVED
                  </div>
                  <Form.Item className="mb_0">  
                    {getFieldDecorator('final_amount', {
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    RETURNED AMOUNT
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('returned_amount', {
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                {/* <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    SHAREHOLDING
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('shareholding', {
                      initialValue: shareholding,
                      rules: [
                        { required: true, message: 'Please fill out this field' },
                        {
                          validator: async (rule, value) => {
                            if (parseInt(value) > 100) {
                              return Promise.reject("Shareholding cannot exceed 100%");
                            } else if (value < 0) {
                              return Promise.reject("Shareholding cannot exceed 100%");
                            }
                          },
                        },
                      ],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div> */}

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    FMV
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('fmv', {
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    PROJECTED IRR
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('irr', {
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    DUE DILIGENCE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('real_diligence', {
                      initialValue: real_diligence,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    REAL DATE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('final_date', {
                      initialValue: moment(),
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <DatePicker disabledDate={this.disabledDates} allowClear={false} />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="btn_group medium-flex-container align-middle align-right">
                <Form.Item className="mb_0">
                  <Button
                    title="Cancel"
                    size="lg"
                    theme="default"
                    className="btn-link radius-8 font-uppercase"
                    type="button"
                    onClick={() => this.toggleInvolveModal(false)}
                  />
                </Form.Item>
                <Form.Item className="mb_0">
                  <Button
                    title="Save"
                    type="submit"
                    size="xl"
                    theme="blue"
                    prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                    className="radius-8"
                    style={{ fontSize: 18 }}
                    loading={handleLoading(loading, "AddInvolveRequest")}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={opportunityPopupVisible}
          onCancel={() => this.toggleOpportunityModal(false)}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.toggleOpportunityModal(false)}>
            <Icon type="cancel" />
          </div>
          <Form onSubmit={this.onSubmit} >
            <div>
              <div className="flex-container align-center text-center">
                <div className="text text-28 text-height-33 font-heavy font-spacing-02 color-grey font-uppercase large-7 mb_35">
                  set investment info
                </div>
              </div>
              <div>
                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    TOTAL RECEIVED
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('final_amount', {
                      initialValue: final_amount,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    RETURNED AMOUNT
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('returned_amount', {
                      initialValue: returned_amount,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                {/* <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    SHAREHOLDING
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('shareholding', {
                      initialValue: shareholding,
                      rules: [
                        { required: true, message: 'Please fill out this field' },
                        {
                          validator: async (rule, value) => {
                            if (parseInt(value) > 100) {
                              return Promise.reject("Shareholding cannot exceed 100%");
                            } else if (value < 0) {
                              return Promise.reject("Shareholding cannot exceed 100%");
                            }
                          },
                        },
                      ],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div> */}

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    FMV
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('fmv', {
                      initialValue: fmv,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    PROJECTED IRR
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('irr', {
                      initialValue: irr,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    DUE DILIGENCE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('real_diligence', {
                      initialValue: real_diligence,
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <NumericInput className="fluid-x" />
                    )}
                  </Form.Item>
                </div>

                <div className="form-item mb_50">
                  <div className="form-item-label text text-height-20 font-semibold color-grey">
                    REAL DATE
                  </div>
                  <Form.Item className="mb_0">
                    {getFieldDecorator('final_date', {
                      initialValue: final_date ? moment(final_date) : moment(),
                      rules: [{ required: true, message: 'Please fill out this field' }],
                    })(
                      <DatePicker disabledDate={this.disabledDates} allowClear={false} />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="btn_group medium-flex-container align-middle align-right">
                <Form.Item className="mb_0">
                  <Button
                    title="Cancel"
                    size="lg"
                    theme="default"
                    className="btn-link radius-8 font-uppercase"
                    type="button"
                    onClick={() => this.toggleOpportunityModal(false)}
                  />
                </Form.Item>
                <Form.Item className="mb_0">
                  <Button
                    title="Save"
                    type="submit"
                    size="xl"
                    theme="blue"
                    prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                    className="radius-8"
                    style={{ fontSize: 18 }}
                    loading={handleLoading(loading, "SetInvestmentInfo")}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={allowToCommitPopupVisible}
          onCancel={() => this.toggleAllowCommitModal(false, null)}
          innerClassNames="shadow-layout bg-white radius-10 relative sm base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.toggleAllowCommitModal(false, null)}>
            <Icon type="cancel" />
          </div>

          <div>
            <div className="custom-headline text text-22 font-bold color-grey project_info__title">
              <h2>Set AA and DD</h2>
            </div>

            <Form onSubmit={this.onAllowToCommitSubmit} >
              <div className="form-item mb_50">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  Allocation Amount
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('allocation_amount', {
                    initialValue: allocation_amount,
                    rules: [{ required: true, message: 'Please fill out this field' }],
                  })(
                    <NumericInput className="fluid-x" />
                  )}
                </Form.Item>
              </div>

              <div className="form-item mb_50">
                <div className="form-item-label text text-height-20 font-semibold color-grey">
                  Diligence Amount
                </div>
                <Form.Item className="mb_0">
                  {getFieldDecorator('diligence_amount', {
                    initialValue: diligence_amount,
                    rules: [{ required: true, message: 'Please fill out this field' }],
                  })(
                    <NumericInput className="fluid-x" />
                  )}
                </Form.Item>
              </div>

              <Form.Item className="mb_0">
                <Button
                  title="Allow"
                  type="submit"
                  size="xl"
                  theme="blue"
                  prefix={<Icon type="checked" size={18} style={{ marginRight: 11 }} />}
                  className="radius-8"
                  style={{ fontSize: 18 }}
                  loading={handleLoading(loading, 'AllowCommit')}
                />
              </Form.Item>
            </Form>
          </div>
        </PopUp>

        <PopUp
          className="relative_parent"
          visible={bidsPopupVisible}
          onCancel={() => this.toggleBidsModal(false)}
          innerClassNames="shadow-layout bg-white radius-10 relative xl base"
        >
          <div className="popup_close color-grey-700" onClick={() => this.toggleBidsModal(false)}>
            <Icon type="cancel" />
          </div>

          <div>
            <div className="custom-headline text text-22 font-bold color-grey project_info__title">
              <h2>Users Winning Bids List</h2>
            </div>
            <Table
              columns={winningBidsColumns}
              dataSource={this.bidsList()}
              pagination={false}
              loading={handleLoading(loading, 'searchBusinessUserBids')}
              className="c-table c-table-bordered users_table"
            />
          </div>
        </PopUp>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.business.loading,
  errorMessage: state.business.errorMessage,
  businessUsers: state.business.businessUsers,
  currentOpenedBusiness: state.business.currentOpenedBusiness,
  currentUserWinningBids: state.business.currentUserWinningBids,
  businessInvolveRequests: state.business.businessInvolveRequests,
  notInvolvedUsers: state.users.notInvolvedUsers,
  investorsCurrentPage: state.users.investorsCurrentPage,
  notInvolvedUsersCount: state.users.notInvolvedUsersCount,
});

const mapDispatchToProps = dispatch => ({
  searchBusinessUsers: businessID => dispatch(searchBusinessUsers(businessID)),
  searchBusinessInvolveRequests: businessID => dispatch(searchBusinessInvolveRequests(businessID)),
  addInvolveRequest: params => dispatch(addInvolveRequest(params)),
  getBusiness: businessID => dispatch(getBusiness(businessID)),
  setBusinessExpired: params => dispatch(setBusinessExpired(params)),
  getNDA: () => dispatch(getNDA()),
  allowCommit: (params) => dispatch(allowCommit(params)),
  allowClose: (user, business) => dispatch(allowClose(user, business)),
  close: (user, business) => dispatch(close(user, business)),
  reopen: (user, business) => dispatch(reopen(user, business)),
  setInvestmentInfo: (params) => dispatch(setInvestmentInfo(params)),
  removeCurrentBusiness: () => dispatch(removeCurrentBusiness()),
  setPegateShareholding: (params) => dispatch(setPegateShareholding(params)),
  removeBusinessInvestor: (params) => dispatch(removeBusinessInvestor(params)),
  searchBusinessUserBids: (businessId, userId) => dispatch(searchBusinessUserBids(businessId, userId)),
  searchNotInvolvedUsers: (businessId) => dispatch(searchNotInvolvedUsers(businessId)),
});

const UserStatusesScreen = Form.create({ name: 'UserStatuses' })(UserStatuses);

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusesScreen);
